<template>
  <div>
    <div class="md-title">
      Book a Meeting (Optional, if you have not spoken to our business team yet)
    </div>
    <Meeting></Meeting>
    <md-button class="mt-n5" @click="goBack">Go Back</md-button>
  </div>
</template>

<script>
import Meeting from "../../components/Inquiry/Meeting.vue";
import notification from "../../notification";
export default {
  name: "BookMeeting",
  components: {
    Meeting,
  },
  mounted() {
    window.addEventListener("message", this.handleHubspotEvent);
  },
  methods: {
    goBack() {
      this.$router.push({ name: "Inquiry List" });
    },
    handleHubspotEvent(e) {
      if (e.data.meetingBookSucceeded === true) {
        notification.success("Your meeting was booked successfully.");
        window.setTimeout(() => {
          this.$router.push({ name: "Inquiry List" });
        }, 3000);
      }
    },
  },
};
</script>
