<template>
  <!-- Start of Meetings Embed Script -->
  <div
    class="meetings-iframe-container"
    data-src="https://meetings.hubspot.com/chathura-sudharshan?embed=true"
  ></div>
  <!-- End of Meetings Embed Script -->
</template>

<script>
export default {
  name: "Meeting",
  mounted() {
    const hubspotScript = document.createElement("script");
    hubspotScript.setAttribute(
      "src",
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
    );
    hubspotScript.setAttribute("type", "text/javascript");
    document.head.appendChild(hubspotScript);
  },
};
</script>
